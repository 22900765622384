<template lang="pug">
  .toggler
    .toggler-item(
      v-for="item in items"
      :class="{ 'selected': isSelectedItem(item) }"
      @click="$emit('change', item)"
    ) {{ $t(`price_calendar.filters.toggler.${item[titleKey]}`) }}
</template>

<script>
  // misc
  import { isEqual } from "lodash-es"

  export default {
    props: {
      items: {
        type: Array,
        default: () => new Array()
      },
      value: {
        type: Object,
        default: () => new Object()
      },
      titleKey: {
        type: String,
        default: "title"
      }
    },

    methods: {
      isSelectedItem(item) {
        return isEqual(item, this.value)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  $toggler-height: 31px
  $toggler-color: $default-purple

  .toggler
    align-items: center
    display: flex
    border: 1px solid $toggler-color
    border-radius: 5px
    color: $toggler-color
    justify-content: center
    font-size: 0.6rem
    overflow: hidden

    &-item
      align-items: center
      display: flex
      cursor: pointer
      justify-content: center
      height: $toggler-height
      width: 50%

      &.selected
        background-color: $toggler-color
        color: $default-white

  @media (min-width: 992px) and (max-width: 1200px)
    .toggler
      font-size: 0.6rem
</style>
